import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Dropdown } from "react-bootstrap";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../_metronic/_partials/widgets/NotAuthorized";
import SaveDialog from "./invoice-dialog/SaveDialog";
import SnackBarTool from "../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { TasksFilter } from "./invoice-filter/TasksFilter";
import TasksTable from "./invoice-table/TasksTable";
import { fetchPermission } from "../../pages/redux/permission";
// import { fetchTasks } from "./_redux/InvoiceAction";
import { initIds } from "../../../_metronic/_partials/controls/RightPanel";
import { initialFilter } from "./InvoiceUIHelpers";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "./InvoiceUIContext";
import CreditTable from "./invoice-table/CreditTable";
import InvoiceReportTable from "./invoice-table/InvoiceReportTable";
import { getReport } from "./_redux/InvoiceCrud";

export const InvoiceReportPage = () => {
  // Use context for managing UI state for tasks
  const invoiceUIContext = useInvoiceUIContext();
  // Use the `intl` hook multilanguage
  const intl = useIntl();

  // Use the `dispatch` hook for dispatching actions
  const dispatch = useDispatch();

  // Get the current user's permissions from the redux store
  const { permission, userData, role } = useSelector(
    (state) => ({
      permission: state.auth.permission,
      userData: state.auth.user,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );

  // Initialize list of panel ids to toggle
  const init = ["kt_create_tasks_panel"];
  const inits = ["kt_change_tasks_panel"];

  // Initialize state variables
  const [check, setCheck] = useState(false);
  const [active, setActive] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [show, setShow] = useState(true);

  // Toggle panel ids when `check` state changes
  useEffect(() => {
    if (check) {
      initIds(inits);
    }
    initIds(init);
  }, [check, init, inits]);

  // Handle completing tasks
  const handleComplete = (submitFunction) => {
    setShow(false);
    setSnack({ call: false, type: "", message: "" });
    const data = {
      task_id: invoiceUIContext.ids,
    };
    submitFunction(data)
      .then((res) => {
        // Display success message and fetch updated tasks
        setSnack({ call: true, type: "success", message: res.data.message });
        // dispatch(fetchTasks(initialFilter));
        invoiceUIContext.setIds([]);
        setShow(true);
      })
      .catch(() => {
        // Set `show` to true if there is an error
        setShow(true);
      });
  };

  // Handle task selection for certain actions
  const handleSelect = (action, taskSelect) => {
    setSnack({ call: false, type: "", message: "" });
    if (taskSelect?.length === 0) {
      // Display error message if no tasks are selected
      setSnack({
        call: true,
        type: "error",
        message: "Please Select a Task",
      });
    } else {
      if (action !== "change") {
        setActive({ type: true, action: action });
      }
    }
    setTimeout(() => {
      setSnack({ call: false, type: "", message: "" });
    }, 2000);
  };

  // Fetch the current user's permissions
  useEffect(() => {
    dispatch(fetchPermission());
  }, [dispatch]);
  const InvoiceUIContext = useInvoiceUIContext();

  const TasksUIProps = useMemo(() => {
    return {
      ids: InvoiceUIContext?.ids,
      setIds: InvoiceUIContext?.setIds,
      queryParams: InvoiceUIContext?.queryParams,
      setQueryParams: InvoiceUIContext?.setQueryParams,
      openEditContactPage: InvoiceUIContext?.openEditContactPage,
      openDeleteContactDialog: InvoiceUIContext?.openDeleteContactDialog,
    };
  }, [InvoiceUIContext]);
  const handleCall = () => {
    getReport(
      TasksUIProps.queryParams,
      TasksUIProps?.ids,
      userData?.franchise_id,
      role && role?.length && role[0]
    )
      .then((res) => {
        const downloadUrl = res.data.data.download_url;
        if (downloadUrl) {
          window.open(downloadUrl, "_blank"); // Open the URL in a new tab.
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="d-flex pb-5" style={{ justifyContent: "space-between" }}>
        <NewBreadCrumbs breadcrumb={true} />
        <div
          style={{
            pointerEvents:
              permission?.task?.create || permission?.task?.edit ? "" : "none",
          }}
        >
          <Dropdown
            className="dropdown-inline"
            drop="down"
            alignRight
            onClick={() => setCheck(check ? false : true)}
          >
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
              title={
                <span>
                  <i className="fad fa-newspaper"></i> Dropdown
                </span>
              }
            >
              {intl.formatMessage({ id: "Actions" })}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ul className="navi navi-hover">
                <li className="navi-item" onClick={() => handleCall()}>
                  <span className="navi-link pointer">
                    <span className="navi-text">Exports</span>
                  </span>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Card>
        <CardBody>
          {permission?.task?.view ? (
            <>
              <TasksFilter type={"rep"} />
              <InvoiceReportTable
                show={show}
                setShow={setShow}
                permission={permission}
                active={active}
                setActive={setActive}
              />
            </>
          ) : (
            <NotAuthorized />
          )}{" "}
        </CardBody>
      </Card>
      {/* modal for save dialog */}
      {active?.type && (
        <SaveDialog
          setActive={setActive}
          active={active}
          handleComplete={handleComplete}
        />
      )}
      {/* task create canva modal */}
      {/* snack bar component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
